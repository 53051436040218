import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { filter, take } from 'rxjs';
import { UTM_PARAMS_KEY } from './utm-params-key';
import { ParamsJSON } from './utm-params.interface';

@Injectable({ providedIn: 'root' })
export class UtmParamsService {
	private readonly activatedRoute = inject(ActivatedRoute);

	saveUTMParams(): void {
		this.activatedRoute.queryParamMap
			.pipe(
				filter((paramMap: ParamMap) => paramMap.keys.length > 0),
				take(1),
			)
			.subscribe((paramMap: ParamMap) => {
				const paramsJSON = this.filterParams(paramMap);
				sessionStorage.setItem(UTM_PARAMS_KEY, JSON.stringify(paramsJSON));
			});
	}

	readUTMParams(): ParamsJSON {
		return JSON.parse(sessionStorage.getItem(UTM_PARAMS_KEY));
	}

	private filterParams(paramMap: ParamMap): ParamsJSON {
		const params = paramMap.keys
			.filter((key) => key.includes('utm'))
			.reduce((acc: ParamsJSON, key: string) => {
				acc[this.removeUTMPrefix(key)] = paramMap.get(key);
				return acc;
			}, {});

		if (JSON.stringify(params) === '{}') {
			return null;
		}

		return params;
	}

	private removeUTMPrefix(str: string): string {
		return str.replace(/utm_(\w+)/g, '$1');
	}
}
