export enum GoogleToolsUrl {
	MAIN_URL = 'https://www.googletagmanager.com/ns.html?id=',
	ANALYTICS = 'https://googletagmanager.com/gtag/js?id=',
	TAG_MANAGER = 'https://www.googletagmanager.com/gtm.js?id=',
}

export enum GoogleToolID {
	ANALYTICS_ID = 'analytics-1',
	TAG_MANAGER_ID = 'tag-manager-1',
	GTAG_INIT_ID = 'analytics-script-1',
}
